import consumer_t_us_company_credit_getCreditDetail from "@/lib/data-service/haolv-default/consumer_t-us-company-credit_getCreditDetail";
import consumer_t_us_company_credit_getCreditFlowList from "@/lib/data-service/haolv-default/consumer_t-us-company-credit_getCreditFlowList";
// import consumer_web_company_updateCompany from "@/lib/data-service/haolv-default/consumer_web_company_updateCompany";

export default {
  name: "Credit",
  data() {
    return {
      flowTypeList: [
        { label: "全部", value: "" },
        { label: "购物", value: 1 },
        { label: "退款", value: 3 },
        { label: "还款", value: 2 },
      ],
      creditDetail: {},
      dateRange: "",
      queryParams: {
        flowType: "",
      },
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      tableData: [],
      // showChangePassword: false,
      // passwordForm: {
      //   wornPassword: '',
      //   password: '',
      //   checkPassword: '',
      // },
      // passwordFormRules: {
      //   wornPassword: [
      //     {
      //       required: true,
      //       message: '请输入原密码',
      //       trigger: 'blur',
      //     },
      //   ],
      //   password: [
      //     {
      //       required: true,
      //       validator: (rule, value, callback) => {
      //         const re = /[\w`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]+/
      //         if (!value) {
      //           callback(new Error('请输入新密码'))
      //         } else if (re.test(value) === false) {
      //           callback(new Error('只能输入数字、字母、特殊字符'))
      //         } else if (value.length < 6 || value.length > 20) {
      //           callback(new Error('密码长度要在6到20个字符之间'))
      //         } else {
      //           callback()
      //         }
      //       },
      //       trigger: 'blur',
      //     },
      //   ],
      //   checkPassword: [
      //     {
      //       required: true,
      //       validator: (rule, value, callback) => {
      //         if (!value) {
      //           callback(new Error('请再次输入新密码'))
      //         } else if (value !== this.passwordForm.password) {
      //           callback(new Error('两次输入密码不一致'))
      //         } else {
      //           callback()
      //         }
      //       },
      //       trigger: 'blur',
      //     },
      //   ],
      // },
    };
  },
  methods: {
    // 过滤对象中为空的属性
    filterObject(obj) {
      if (!typeof obj === "object") return;

      for (var key in obj) {
        if (
          obj.hasOwnProperty(key) &&
          (obj[key] == null || obj[key] == undefined || obj[key] == "")
        ) {
          delete obj[key];
        }
      }
      return obj;
    },
    // 初始化
    init() {
      consumer_t_us_company_credit_getCreditDetail().then((res) => {
        this.creditDetail = res.datas;
      });
    },
    // 流水
    getFlowList(arg) {
      if (arg == 1) this.pagination.currentPage = 1;
      let _queryParams = this.getQueryParameter();
      consumer_t_us_company_credit_getCreditFlowList(_queryParams).then(
        (res) => {
          this.tableData = res.datas.list;
          this.pagination.total = res.datas.totalCount;
        }
      );
    },
    // 获取查询条件
    getQueryParameter() {
      let _params = Object.assign({}, this.queryParams);
      _params.currentPage = this.pagination.currentPage;
      _params.pageSize = this.pagination.pageSize;
      return this.filterObject(_params);
    },
    // 搜索
    onSearch() {
      if (
        this.dateRange != null &&
        this.dateRange != undefined &&
        this.dateRange != ""
      ) {
        let [start, end] = this.dateRange;
        // 选择了日期
        this.queryParams.transactionStartTime = this.$moment(start).format(
          "YYYY-MM-DD"
        );
        this.queryParams.closingTime = this.$moment(end).format("YYYY-MM-DD");
      }
      this.getFlowList(1);
    },
    onChangeDateRange(value) {
      if (value == null) {
        this.dateRange = "";
        this.queryParams.transactionStartTime = "";
        this.queryParams.closingTime = "";
      }
    },
    handleSizeChange(pageSize) {
      this.pagination.pageSize = pageSize;
      this.getFlowList(1);
    },
    handleCurrentChange(currentPage) {
      this.pagination.currentPage = currentPage;
      this.getFlowList();
    },
    goBill(name) {
      this.$router.push({ name: name });
    },
    // 还款
    toPayment() {
      this.$router.push({
        name: "admin-finance-credit-repayments",
        query: {
          billId: this.creditDetail.billId,
        },
      });
    },
    // 修改支付密码
    // onShowChangePassowrd() {
    //   this.passwordForm = {
    //     wornPassword: '',
    //     password: '',
    //     checkPassword: '',
    //   }
    //   this.showChangePassword = true
    // },
    // // 确认修改密码
    // onSubmitChangePassword() {
    //   this.$refs.passwordForm.validate((valid) => {
    //     if (valid) {
    //       let body = {
    //         password: this.passwordForm.password,
    //         wornPassword: this.passwordForm.wornPassword,
    //       }
    //       consumer_web_company_updateCompany(body).then(() => {
    //         this.$message.success('修改成功')
    //       }).finally(() => {
    //         this.showChangePassword = false
    //       })
    //     } else {
    //       this.$message.error('请检查是否有字段未填写')
    //       return false
    //     }
    //     this.showChangePassword = false
    //   });
    // },
  },
  // created() {
  //   this.init();
  //   this.getFlowList();
  // },
  activated() {
    this.init();
    this.getFlowList();
  },
};
